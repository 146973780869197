import { graphql, Link, PageProps } from "gatsby";
import React from "react";
import { Col, Row } from "react-bootstrap";
import SearchResult from "../../components/SearchResult";
import { videoFromNode } from "../../data/videos";

import Layout from "../../layouts/default";

export const Head = () => (
  <title>Chinese Comprehensible Input related to politics | MandarInput</title>
);

export const PoliticsPage = ({
  data,
}: PageProps<Queries.PoliticsVideosQuery>) => {
  const filteredVideos = data.allVideosYaml.edges.map((node) =>
    videoFromNode(node.node)
  );

  return (
    <Layout>
      <Row>
        <Col sm={12} lg={4}>
          <div className="card">
            <h2>Learn Mandarin with politics videos</h2>
            <p>
              If you&apos;re learning Mandarin it&apos;s useful to listen to
              videos on topics that you are interested in, and suit your
              purposes for learning.
            </p>
            <p>
              These videos all relate to politics, both in history and modern
              times and inside and outside China. We have{" "}
              <Link to="/">lots of other videos</Link> if you&apos;re interested
              in other topics.
            </p>
            <p>
              Politics is an important and complex topic, and it can be daunting
              to try to learn about it in a new language. However, learning
              about politics can be a great way to deepen your understanding of
              a language and culture. With this list of videos, you can learn
              more about politics in Chinese, which can help you to become more
              fluent in the language. Whether you&apos;re a beginner or a more
              advanced learner, these videos can provide a great introduction to
              Chinese political language and concepts. From explaining the
              structure of the Chinese government to introducing the history of
              Chinese politics, these videos will help you to understand the
              language and culture of politics in China.
            </p>
          </div>
        </Col>
        <Col sm={12} lg={8}>
          {filteredVideos.map(
            (video) =>
              video !== null && (
                <SearchResult
                  key={video.videoId}
                  video={video}
                  viewed={false}
                />
              )
          )}
        </Col>
      </Row>
    </Layout>
  );
};

export default PoliticsPage;

export const pageQuery = graphql`
  query PoliticsVideos {
    allVideosYaml(filter: { topics: { in: "politics" } }) {
      edges {
        node {
          slug
          hsk
          duration
          channel
          topics
          title
          videoId
          subtitles {
            chinese
            english
            pinyin
          }
          speechSpeed
          difficulty
        }
      }
    }
  }
`;
